import { push } from 'connected-react-router';
import React, { useState } from 'react';
import styled from 'styled-components';
import ApiManagedProfiles, { CreateAttachedListing } from '../../../../api/api-managed-profiles';
import { ListingType } from '../../../../api/model';
import store from '../../../../store/store';
import StyledModal from '../../../Shared/styled-modal';
import { Button, Divider, FormGroup, Input, Label, Row, Spinner } from '../../../style';

type TMSType = 'EXTERNAL' | 'TILED';

interface TMSLayerAddProps {
    userId: string;
}

const ProfileExternalTileLayerAdd = ({ userId }: TMSLayerAddProps) => {
    const [isAddLayerModalOpen, setAddLayerModalOpen] = useState(false);
    const [isAddingLayer, setIsAddingLayer] = useState(false);
    const [type, setType] = useState<TMSType>('EXTERNAL');
    const [url, setUrl] = useState('');
    const [source, setSource] = useState('soar-r2');

    const handleAddNewLayer = () => {
        setIsAddingLayer(true);
        const body: CreateAttachedListing = {
            listingType: ListingType.EXTERNAL_TILE_LAYER,
            metadata: {
                title: url,
            },
            url: url,
            source: type === 'TILED' ? source : undefined,
        };
        ApiManagedProfiles.createListing(userId, body)
            .then((res) => {
                store.dispatch(push(`/profiles/${userId}/listings/${res.id}`));
            })
            .catch((err) => {
                alert('An error occurred while adding new layer: ' + err.message);
            })
            .finally(() => {
                setIsAddingLayer(false);
            });
    };

    return (
        <React.Fragment>
            <Button onClick={() => setAddLayerModalOpen(true)}>
                <i className="fa fa-plus-circle" />
                Add TMS/XYZ layer
            </Button>

            <StyledModal
                isOpen={isAddLayerModalOpen}
                toggle={() => setAddLayerModalOpen(false)}
                title="Add TMS/XYZ layer"
                width="600px"
            >
                <div style={{ padding: '10px' }}>
                    <FormGroup>
                        <Label>Type</Label>
                        <Input type="select" value={type} onChange={(e) => setType(e.target.value as TMSType)}>
                            <option value="EXTERNAL">External</option>
                            <option value="TILED">Tiled</option>
                        </Input>
                    </FormGroup>
                    {type === 'EXTERNAL' && (
                        <FormGroup>
                            <Label>The url must contain the following parameters {'{z}/{x}/{y}'}</Label>
                            <Input
                                type="text"
                                placeholder="Enter layer URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </FormGroup>
                    )}
                    {type === 'TILED' && (
                        <React.Fragment>
                            <FormGroup>
                                <Label>Enter file name (key) for the layer metadata file</Label>
                                <Input
                                    type="text"
                                    placeholder="Metadata file name"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Source of tiled dataset</Label>
                                <Input
                                    readOnly
                                    disabled
                                    type="text"
                                    placeholder="Enter source"
                                    value={source}
                                    onChange={(e) => setSource(e.target.value)}
                                />
                            </FormGroup>
                        </React.Fragment>
                    )}
                </div>
                <ActionButtonContainer>
                    <Button disabled={isAddingLayer} onClick={() => handleAddNewLayer()}>
                        {isAddingLayer ? (
                            <>
                                <LoadingSpinner />
                                Add new layer
                            </>
                        ) : (
                            <>Add new layer</>
                        )}
                    </Button>
                    <Button color="warning" onClick={() => setAddLayerModalOpen(false)}>
                        Cancel
                    </Button>
                </ActionButtonContainer>
            </StyledModal>
        </React.Fragment>
    );
};

export default ProfileExternalTileLayerAdd;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0px;
`;

const LoadingSpinner = styled(Spinner)`
    margin-right: 8px;
    font-size: 10px;
    width: 15px;
    height: 15px;
`;

const AddLayerLabel = styled(Label)`
    font-weight: 200;
    font-size: 16px;
    margin: 10px 0px 0px 10px;
`;
