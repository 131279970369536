import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import {
    AttachedListingDTO,
    AttachedSTACCatalogDTO,
    AttachedWmsServerDTO,
    ManagedProfileFullDTO,
} from '../../../../api/api-managed-profiles';
import ApiManagedProfiles from '../../../../api/api-managed-profiles';
import NotificationUtil from '../../../../lib/notification-util';
import {
    Button,
    Col,
    Divider,
    FormGroup,
    Input,
    ConfirmModalFooter,
    ConfirmButtonContainer,
    Label,
    Row,
    Subtitle,
    Table,
    TableWrapper,
} from '../../../style';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import CopyBox from '../../../Shared/copy-box';
import TextInputWithClear from '../../../Shared/text-input-with-clear';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ListingMetadata } from '../../../../api/api-managed-profiles';
import CategoriesInput from '../../../Shared/categories-input-field';
import TagInputField from '../../../Shared/tag-input-field';
import ViewHelper from '../../../view-helper';
import ConfirmModal from '../../../Shared/confirm-modal';
import SoarModal from '../../../Shared/soar-modal';

interface TMSServerLayersProps {
    profile: ManagedProfileFullDTO;
}

const COLS_LISTINGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'listingType',
        text: 'Listing Type',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'processing',
        text: 'Processing',
        headerStyle: () => {
            return {
                width: '100px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.processing) {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
    {
        dataField: 'public',
        text: 'Available in Soar',
        headerStyle: () => {
            return {
                width: '140px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.reviewSoar == 'APPROVED') {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
];

const COLS_WMS_SERVERS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'url',
        text: 'URL',
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: () => {
            return { textAlign: 'center', width: 'auto', maxWidth: '250px', overflow: 'hidden' };
        },
        formatter: (_cell, row: AttachedWmsServerDTO) => {
            return <CopyBox onCopyMessage="Server URL copied to clipboard!">{row.url}</CopyBox>;
        },
    },
    {
        dataField: 'layersCount',
        text: 'Total layers',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedWmsServerDTO) => row.listings?.length,
    },
];

const COLS_STAC_CATALOGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'catalogsCount',
        text: 'Total sub-catalogs',
    },
    {
        dataField: 'itemsCount',
        text: 'Total items',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedSTACCatalogDTO) => row.listings?.length,
    },
];

const paginationOptions = {
    sizePerPageList: [
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
        {
            text: '100',
            value: 100,
        },
        {
            text: '1000',
            value: 1000,
        },
    ],
};

type SortBy = 'title' | 'id';
type SortOrder = 'asc' | 'desc';

const ProfileListings = ({ profile }: TMSServerLayersProps) => {
    const { listings, wmsServers, wmsOrphanedListings, stacCatalogs } = profile;
    const [textFilter, setTextFilter] = useState<string>('');
    const [sortBy, setSortBy] = useState<SortBy>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [listingsFiltered, setListingsFiltered] = useState<AttachedListingDTO[]>(listings);
    const [wmsServersFiltered, setWmsServersFiltered] = useState<AttachedWmsServerDTO[]>(wmsServers);
    const [wmsOrphanedListingsFiltered, setWmsOrphanedListingsFiltered] =
        useState<AttachedListingDTO[]>(wmsOrphanedListings);
    const [stacCatalogsFiltered, setStacCatalogsFiltered] = useState<AttachedSTACCatalogDTO[]>(stacCatalogs);
    const [selectedListings, setSelectedListings] = useState<Set<number>>(new Set());
    const [selectedwmsOrphanedListings, setSelectedwmsOrphanedListings] = useState<Set<number>>(new Set());
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisibilityOpen, setModalVisibilityOpen] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [license, setLicense] = useState<string>('');
    const [visibility, setVisibility] = useState(false);
    const [wmsModalOpen, setWmsModalOpen] = useState(false);
    const [wmsVisibility, setWmsVisibility] = useState(false);
    const [wmsModelVisibilityOpen, setWmsModelVisibilityOpen] = useState(false);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');
        setListingsFiltered(
            listings
                .filter(
                    (listing) =>
                        textFilterRegEx.test(listing.title) ||
                        textFilterRegEx.test(listing.listingType) ||
                        textFilterRegEx.test(listing.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setWmsServersFiltered(
            wmsServers
                .filter(
                    (wmsServer) =>
                        textFilterRegEx.test(wmsServer.title) ||
                        textFilterRegEx.test(wmsServer.url) ||
                        textFilterRegEx.test(wmsServer.standard) ||
                        textFilterRegEx.test(wmsServer.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setWmsOrphanedListingsFiltered(
            wmsOrphanedListings
                .filter(
                    (listing) =>
                        textFilterRegEx.test(listing.title) ||
                        textFilterRegEx.test(listing.listingType) ||
                        textFilterRegEx.test(listing.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setStacCatalogsFiltered(
            stacCatalogs
                .filter((catalog) => textFilterRegEx.test(catalog.title) || textFilterRegEx.test(catalog.id.toString()))
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
    }, [listings, wmsServers, stacCatalogs, wmsOrphanedListings, textFilter, sortBy, sortOrder]);

    const onClickRowWMS = {
        onClick: (_e, row: AttachedWmsServerDTO) => {
            store.dispatch(push(`/wms/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/wms/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowSTAC = {
        onClick: (_e, row: AttachedSTACCatalogDTO) => {
            store.dispatch(push(`/stac/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/stac/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowListing = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            const win = window.open(
                `/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`,
                '_blank'
            );
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowWmsOrphans = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            const win = window.open(
                `/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`,
                '_blank'
            );
            if (!win) {
                win.focus();
            }
        },
    };

    const handleSelectListing = (row: AttachedListingDTO, isSelected: boolean) => {
        setSelectedListings((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (isSelected) {
                newSelected.add(row.id);
            } else {
                newSelected.delete(row.id);
            }
            return newSelected;
        });
    };

    const handleSelectAllListings = (isSelected: boolean, rows: AttachedListingDTO[]) => {
        if (isSelected) {
            const allIds = rows.map((row) => row.id);
            setSelectedListings(new Set(allIds));
        } else {
            setSelectedListings(new Set());
        }
    };

    const handleSelectAllWmsOrphans = (isSelected: boolean, rows: AttachedListingDTO[]) => {
        if (isSelected) {
            const allIds = rows.map((row) => row.id);
            setSelectedwmsOrphanedListings(new Set(allIds));
        } else {
            setSelectedwmsOrphanedListings(new Set());
        }
    };

    const handleSelectWmsOrphans = (row: AttachedListingDTO, isSelected: boolean) => {
        setSelectedwmsOrphanedListings((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (isSelected) {
                newSelected.add(row.id);
            } else {
                newSelected.delete(row.id);
            }
            return newSelected;
        });
    };

    const handleDeleteSelected = () => {
        setLoading(true);
        async function deleteSelected() {
            try {
                await ApiManagedProfiles.deleteBulkListings(profile.user.userId, Array.from(selectedListings));
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        deleteSelected();
    };

    const handleDeleteSelectedWmsOrphans = () => {
        setLoading(true);
        async function deleteSelected() {
            try {
                await ApiManagedProfiles.deleteBulkListings(
                    profile.user.userId,
                    Array.from(selectedwmsOrphanedListings)
                );
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        deleteSelected();
    };

    const handleUpdateMetadata = () => {
        setLoading(true);
        async function updateMetadata() {
            try {
                const metadata: ListingMetadata = {
                    categories: categories,
                    tags: tags,
                    license: license,
                };
                await ApiManagedProfiles.updateListingMetadata(
                    profile.user.userId,
                    Array.from(selectedListings),
                    metadata
                );
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        updateMetadata();
    };

    const handleUpdateWmsOrphansMetadata = () => {
        setLoading(true);
        async function updateWmsMetadata() {
            try {
                const metadata: ListingMetadata = {
                    categories: categories,
                    tags: tags,
                    license: license,
                };
                await ApiManagedProfiles.updateListingMetadata(
                    profile.user.userId,
                    Array.from(selectedwmsOrphanedListings),
                    metadata
                );
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        updateWmsMetadata();
    };

    const handleVisibilityUpdate = () => {
        setLoading(true);
        async function updateVisibility() {
            try {
                await ApiManagedProfiles.updateBulkListingVisibility(
                    profile.user.userId,
                    Array.from(selectedListings),
                    visibility
                );
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        updateVisibility();
    };

    const handleVisibilityUpdateWmsOrphans = () => {
        setLoading(true);
        async function updateWmsVisibility() {
            try {
                await ApiManagedProfiles.updateBulkListingVisibility(
                    profile.user.userId,
                    Array.from(selectedwmsOrphanedListings),
                    wmsVisibility
                );
                store.dispatch(push(`/profiles/${profile.user.userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setLoading(false);
            }
        }
        updateWmsVisibility();
    };

    const handleShowModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const handleWmsShowModal = () => setWmsModalOpen(true);
    const handleWmsCloseModal = () => setWmsModalOpen(false);
    const handleShowVisibilityModal = () => setModalVisibilityOpen(true);
    const handleCloseVisibilityModal = () => setModalVisibilityOpen(false);
    const handleWmsShowVisibilityModal = () => setWmsModelVisibilityOpen(true);
    const handleWmsCloseVisibilityModal = () => setWmsModelVisibilityOpen(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleUpdateMetadata();
        handleCloseModal();
    };

    const handleWmsFormSubmit = (e) => {
        e.preventDefault();
        handleUpdateWmsOrphansMetadata();
        handleWmsCloseModal();
    };

    const handleVisibilitySubmit = (e) => {
        e.preventDefault();
        handleVisibilityUpdate();
        handleCloseVisibilityModal();
    };

    const handleVisibilitySubmitWmsOrphans = (e) => {
        e.preventDefault();
        handleVisibilityUpdateWmsOrphans();
        handleWmsCloseVisibilityModal();
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label>Filter by ...</Label>
                        <TextInputWithClear value={textFilter} onChange={setTextFilter} placeholder="Search term ..." />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>Sort by</Label>
                        <Input
                            value={sortBy}
                            onChange={(e) => {
                                setSortBy(e.target.value);
                            }}
                            type="select"
                            name="sortBy"
                        >
                            <option value={'id'}>ID</option>
                            <option value={'title'}>Title</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>Sort order</Label>
                        <Input
                            value={sortOrder}
                            onChange={(e) => {
                                setSortOrder(e.target.value);
                            }}
                            type="select"
                            name="sortOrder"
                        >
                            <option value={'asc'}>Ascending</option>
                            <option value={'desc'}>Descending</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            {wmsServersFiltered.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {wmsServers.length} WMS/WMTS servers</Subtitle>
                    <ToolkitProvider keyField="id" data={wmsServersFiltered} columns={COLS_WMS_SERVERS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        {...props.baseProps}
                                        rowEvents={onClickRowWMS}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                    <Divider />
                </React.Fragment>
            ) : null}

            {stacCatalogsFiltered.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {stacCatalogs.length} STAC catalogs</Subtitle>
                    <ToolkitProvider keyField="id" data={stacCatalogsFiltered} columns={COLS_STAC_CATALOGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        {...props.baseProps}
                                        rowEvents={onClickRowSTAC}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                    <Divider />
                </React.Fragment>
            ) : null}

            {listings.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {listings.length} attached listings</Subtitle>
                    <FormGroup check>
                        <Label check></Label>
                    </FormGroup>
                    <ToolkitProvider keyField="id" data={listingsFiltered} columns={COLS_LISTINGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        rowEvents={onClickRowListing}
                                        pagination={paginationFactory(paginationOptions)}
                                        selectRow={{
                                            mode: 'checkbox',
                                            clickToSelect: true,
                                            onSelect: handleSelectListing,
                                            onSelectAll: handleSelectAllListings,
                                        }}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {selectedListings.size > 0 ? (
                <div>
                    <ConfirmModal
                        title={`Delete`}
                        message={`Are you sure you want to delete all ${selectedListings.size} selected listings? Action cannot be undone`}
                        onConfirm={() => handleDeleteSelected()}
                        color={'danger'}
                    />
                    <Button onClick={handleShowModal}>Update Metadata</Button>
                    <Button onClick={handleShowVisibilityModal}>Update Visibility</Button>

                    <SoarModal
                        isOpen={modalOpen}
                        onClosed={handleCloseModal}
                        width="600px"
                        title="Enter Metadata to update"
                    >
                        <FormGroup>
                            <Label for="categories" xs={12}>
                                Categories
                            </Label>
                            <CategoriesInput
                                values={categories}
                                onChange={(selectedCategories) => {
                                    console.log('selectedCategories: ', selectedCategories);
                                    setCategories(selectedCategories);
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="tags" xs={12}>
                                Tags (to help with search)
                            </Label>
                            <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="license" xs={12}>
                                License
                            </Label>
                            <Input
                                value={license}
                                onChange={(e) => {
                                    setLicense(e.target.value);
                                }}
                                type="select"
                                name="license"
                            >
                                {<option value={''}>Choose license</option>}
                                {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <ConfirmModalFooter margin="0px 15px 0px 0px">
                            <ConfirmButtonContainer>
                                <Button onClick={handleFormSubmit}>Update</Button>
                                <Button color="warning" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                            </ConfirmButtonContainer>
                        </ConfirmModalFooter>
                    </SoarModal>

                    <SoarModal
                        isOpen={modalVisibilityOpen}
                        onClosed={handleCloseVisibilityModal}
                        width="600px"
                        title="Update Visibility"
                    >
                        <FormGroup tag="fieldset">
                            <Label for="visibility" xs={12}>
                                Visibility
                            </Label>
                            <FormGroup check inline>
                                <Input
                                    name="visibility"
                                    type="radio"
                                    required={true}
                                    onChange={() => setVisibility(true)}
                                    checked={visibility === true}
                                />
                                <Label check onClick={() => setVisibility(true)}>
                                    Approved
                                </Label>
                            </FormGroup>
                            <FormGroup check inline style={{ marginLeft: '24px' }}>
                                <Input
                                    name="visibility"
                                    type="radio"
                                    onChange={() => setVisibility(false)}
                                    checked={visibility === false}
                                />
                                <Label check onClick={() => setVisibility(false)}>
                                    Rejected
                                </Label>
                            </FormGroup>
                        </FormGroup>

                        <ConfirmModalFooter margin="0px 15px 0px 0px">
                            <ConfirmButtonContainer>
                                <Button onClick={handleVisibilitySubmit}>Update</Button>
                                <Button color="warning" onClick={handleCloseVisibilityModal}>
                                    Cancel
                                </Button>
                            </ConfirmButtonContainer>
                        </ConfirmModalFooter>
                    </SoarModal>
                </div>
            ) : null}

            {wmsOrphanedListings.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {wmsOrphanedListings.length} orphaned WMS listings</Subtitle>
                    <FormGroup check>
                        <Label check></Label>
                    </FormGroup>
                    <ToolkitProvider keyField="id" data={wmsOrphanedListingsFiltered} columns={COLS_LISTINGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        rowEvents={onClickRowWmsOrphans}
                                        pagination={paginationFactory(paginationOptions)}
                                        selectRow={{
                                            mode: 'checkbox',
                                            clickToSelect: true,
                                            onSelect: handleSelectWmsOrphans,
                                            onSelectAll: handleSelectAllWmsOrphans,
                                        }}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {selectedwmsOrphanedListings.size > 0 ? (
                <div>
                    <ConfirmModal
                        title={`Delete`}
                        message={`Are you sure you want to delete all ${selectedwmsOrphanedListings.size} selected listings? Action cannot be undone`}
                        onConfirm={() => handleDeleteSelectedWmsOrphans()}
                        color={'danger'}
                    />
                    <Button onClick={handleWmsShowModal}>Update Metadata</Button>
                    <Button onClick={handleWmsShowVisibilityModal}>Update Visibility</Button>

                    <SoarModal
                        isOpen={wmsModalOpen}
                        onClosed={handleWmsCloseModal}
                        width="600px"
                        title="Enter Metadata to update"
                    >
                        <FormGroup>
                            <Label for="categories" xs={12}>
                                Categories
                            </Label>
                            <CategoriesInput
                                values={categories}
                                onChange={(selectedCategories) => {
                                    console.log('selectedCategories: ', selectedCategories);
                                    setCategories(selectedCategories);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags" xs={12}>
                                Tags (to help with search)
                            </Label>
                            <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="license" xs={12}>
                                License
                            </Label>
                            <Input
                                value={license}
                                onChange={(e) => {
                                    setLicense(e.target.value);
                                }}
                                type="select"
                                name="license"
                            >
                                {<option value={''}>Choose license</option>}
                                {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <ConfirmModalFooter margin="0px 15px 0px 0px">
                            <ConfirmButtonContainer>
                                <Button onClick={handleWmsFormSubmit}>Update</Button>
                                <Button color="warning" onClick={handleWmsCloseModal}>
                                    Cancel
                                </Button>
                            </ConfirmButtonContainer>
                        </ConfirmModalFooter>
                    </SoarModal>
                    <SoarModal
                        isOpen={wmsModelVisibilityOpen}
                        onClosed={handleWmsCloseVisibilityModal}
                        width="600px"
                        title="Update Visibility"
                    >
                        <FormGroup tag="fieldset">
                            <Label for="visibility" xs={12}>
                                Visibility
                            </Label>
                            <FormGroup check inline>
                                <Input
                                    name="wmsVisibility"
                                    type="radio"
                                    required={true}
                                    onChange={() => setWmsVisibility(true)}
                                    checked={wmsVisibility === true}
                                />
                                <Label check onClick={() => setWmsVisibility(true)}>
                                    Approved
                                </Label>
                            </FormGroup>
                            <FormGroup check inline style={{ marginLeft: '24px' }}>
                                <Input
                                    name="wmsVisibility"
                                    type="radio"
                                    onChange={() => setWmsVisibility(false)}
                                    checked={visibility === false}
                                />
                                <Label check onClick={() => setWmsVisibility(false)}>
                                    Rejected
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <ConfirmModalFooter margin="0px 15px 0px 0px">
                            <ConfirmButtonContainer>
                                <Button onClick={handleVisibilitySubmitWmsOrphans}>Update</Button>
                                <Button color="warning" onClick={handleWmsCloseVisibilityModal}>
                                    Cancel
                                </Button>
                            </ConfirmButtonContainer>
                        </ConfirmModalFooter>
                    </SoarModal>
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default ProfileListings;

const Checkmark = styled.i`
    color: green;
`;
