import filesize from 'filesize';
import { LatLng } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO, ListingType, Metadata, Review } from '../../api/model';
import Config from '../../config';
import GeoUtil from '../../lib/geo-util';
import {
    Alert,
    Button,
    Col,
    Collapse,
    ConfirmButtonContainer,
    ConfirmModalFooter,
    ConfirmModalText,
    FormGroup,
    Input,
    Label,
    List,
    Multiselect,
    Row,
    SubtitleHint,
} from '../style';
import ViewHelper from '../view-helper';
import CategoriesInput, { filterPrimaryCategories } from './categories-input-field';
import ErrorPanel from './error-panel';
import FormattedDetailsTable, { Line } from './formatted-details-table';
import IconButton from './icon-button';
import ListingImageMap from './listing-image-map';
import SingleFeatureMap from './single-feature-map';
import SoarModal from './soar-modal';
import TagInputField from './tag-input-field';
import { findTemporalCategory } from './temporal-input-field';
import ListingMap from './listing-map';

interface Props {
    listing: ListingDTO;
    allowEditing?: boolean;
}

const ListingDetails = (props: Props) => {
    const metadata: Metadata = JSON.parse(props.listing.metadata);
    const { listing, allowEditing } = props;
    const [modal, setModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isReviewLogsOpen, setIsReviewLogsOpen] = useState(false);
    const [editable, setEditable] = useState(false);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [seoTitle, setSeoTitle] = useState<string>('');
    const [primaryCategories, setPrimaryCategories] = useState<string[]>([]);
    const [license, setLicense] = useState('');
    const [distoredGeometry, setDistoredGeometry] = useState<string | undefined>(undefined);

    const [tags, setTags] = useState<string[]>([]);
    const [corners, setCorners] = useState<LatLng[] | undefined>(undefined);

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const toggle = () => setIsOpen(!isOpen);

    const wmsServerLink = (listing: ListingDTO) => `/wms/${listing.layerWmsServerId}`;

    useEffect(() => {
        if (listing) {
            setListingDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setListingDetails = () => {
        setTitle(listing.title || '');
        setDescription(listing.description || '');
        setSeoTitle(listing.seoTitle);
        setTags(listing.tags || []);
        setDistoredGeometry(listing.distoredGeometry);
        setLicense(listing.license || '');
        setPrimaryCategories(filterPrimaryCategories(listing.categories) || []);
    };

    let gdal = {};
    if (listing.gdalInfo) {
        gdal = JSON.parse(listing.gdalInfo);
    }

    const downloadFile = async () => {
        let url;
        if (listing.fullResUrl) {
            url = listing.fullResUrl;
        } else {
            const linkDto = await ApiListings.getDownloadLink(listing.id);
            url = linkDto.presignedUrl;
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.click();
    };

    const handleUpdateListing = async () => {
        const updatedCategories = primaryCategories;
        if (updatedCategories.length > 3) {
            toast.error('1 - 3 categories required!');
            return;
        }
        const temporal = findTemporalCategory(listing.categories || []);
        if (temporal) {
            updatedCategories.push(temporal);
        }
        if (!title || !description || !license) {
            toast.error(
                `Please check your details: missing
                    ${!title ? 'title ' : ''}
                    ${!description ? 'description ' : ''}
                    ${!license ? 'license ' : ''}
                    `
            );
            return;
        }

        const regex = new RegExp('^([a-zA-Z0-9])+(-[a-zA-Z0-9]+)+$');
        if (seoTitle && !regex.test(seoTitle)) {
            toast.error(
                'Invalid format for SEO title prefix - it should consist of alphanumeric characters separated by hyphen'
            );
            return;
        }

        //update only if there are changes
        if (distoredGeometry && corners) {
            try {
                setIsUpdating(true);
                const positions = [corners[3], corners[2], corners[0], corners[1]];
                const distoredGeometryWKT = GeoUtil.latLngListsToWKT(positions);
                const success = await ApiListings.updateDistoredGeometry(listing.id, distoredGeometryWKT);
                if (success) {
                    toast.info('Successfully updated image position');
                    setDistoredGeometry(distoredGeometryWKT);
                } else {
                    toast.error('Error updating image position');
                }
            } catch (err) {
                toast.error('Update listing error: ' + err.toString());
            } finally {
                setIsUpdating(false);
            }
        }

        try {
            setIsUpdating(true);
            const success = await ApiListings.updateListing(
                listing.id,
                title,
                description,
                seoTitle || null,
                tags,
                updatedCategories,
                license
            );
            if (success) {
                toast.info('Successfully updated listing');
                setEditable(false);
            } else {
                toast.error('Update listing error');
            }

            setIsUpdating(false);
        } catch (err) {
            toast.error('Update listing error: ' + err.toString());
        }
    };

    const handleCloseEditing = (editing: boolean) => {
        setEditable(editing);
        setListingDetails();
    };
    ``;

    const extractSeoTitlePrefix = (seoTitle: string) => {
        if (!seoTitle) {
            return '';
        }

        const tokens = seoTitle.split('-');
        if (tokens.length < 2) {
            return '';
        }

        return tokens.slice(0, -1).join('-');
    };

    return (
        <React.Fragment>
            <Row>
                <EditAlert color="danger" isOpen={editable}>
                    <React.Fragment>
                        <h3>{`Editing ${title ? title : listing.id}`}</h3>
                        <FormGroup>
                            <RequiredLabel form="title">
                                Title <span>*</span>
                            </RequiredLabel>
                            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <RequiredLabel form="description">
                                Description <span>*</span>
                            </RequiredLabel>
                            <Input
                                type="textarea"
                                rows={5}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>
                        {seoTitle ? (
                            <FormGroup>
                                <RequiredLabel form="seoTitle">
                                    SEO Title Prefix <span>*</span>
                                </RequiredLabel>
                                <Input
                                    type="text"
                                    value={extractSeoTitlePrefix(seoTitle)}
                                    onChange={(e) => {
                                        const prefix = e.target.value.trim().toLowerCase();
                                        if (!prefix) {
                                            setSeoTitle(`${listing.id}`);
                                        } else {
                                            setSeoTitle(`${prefix}-${listing.id}`);
                                        }
                                    }}
                                />
                                <InputHelpText>SEO Title: /{seoTitle}</InputHelpText>
                            </FormGroup>
                        ) : null}
                        <FormGroup>
                            <RequiredLabel form="categories">
                                Categories <span>*</span>
                            </RequiredLabel>
                            <CategoriesInput
                                values={primaryCategories}
                                onChange={(selectedCategories) => {
                                    setPrimaryCategories(selectedCategories);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label form="title">Tags</Label>
                            <TagInputField onTagInput={(t) => setTags(t)} tags={tags} />
                        </FormGroup>
                        <FormGroup>
                            <RequiredLabel for="license">
                                License <span>*</span>
                            </RequiredLabel>
                            <Input
                                value={license}
                                onChange={(e) => {
                                    setLicense(e.target.value);
                                }}
                                type="select"
                                name="license"
                            >
                                {!license && <option value={''}>Choose license</option>}
                                {!Object.values(ViewHelper.LISTINGS_LICENSES).includes(listing.license) && (
                                    <option value={listing.license}>{listing.license}</option>
                                )}
                                {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                                    <option value={value}>{value}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        {listing.listingType === ListingType.IMAGE && listing.previewUrl && distoredGeometry && (
                            <FormGroup>
                                <Label form="title">Image position</Label>
                                <ListingImageMap
                                    distoredGeometry={distoredGeometry}
                                    previewUrl={listing.previewUrl}
                                    editable={true}
                                    onCornersUpdated={setCorners}
                                />
                            </FormGroup>
                        )}

                        <ConfirmEditButtonContainer>
                            <SubmitButton onClick={handleUpdateListing} disabled={isUpdating}>
                                {isUpdating ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                            </SubmitButton>
                            <Button color="warning" onClick={() => handleCloseEditing(false)}>
                                Close
                            </Button>
                        </ConfirmEditButtonContainer>
                    </React.Fragment>
                </EditAlert>

                {!editable && (
                    <Col xs={12}>
                        <ListingName title={`ID: ${listing.id}`}>
                            {listing.review === Review.APPROVED ? (
                                <a
                                    target="_blank"
                                    href={`${Config.CLIENT_URL}/browse/${listing.id}`}
                                    rel="noopener noreferrer"
                                >
                                    {title}
                                </a>
                            ) : (
                                title
                            )}
                            {allowEditing ? (
                                <span>
                                    <IconButton
                                        title={'Edit Details'}
                                        onClick={() => setEditable(!editable)}
                                        faIcon="fa-edit"
                                        fontSize="30px"
                                    />
                                </span>
                            ) : null}
                        </ListingName>
                        <ListingUploader>
                            Uploaded by{' '}
                            <a
                                target="_blank"
                                href={`${Config.CLIENT_URL}/profile/${listing.userId}`}
                                rel="noopener noreferrer"
                            >
                                {listing.userEmail}
                            </a>{' '}
                            ({listing.userName}) on {ViewHelper.formatDateWithTime(listing.createdAt)}
                        </ListingUploader>
                    </Col>
                )}
                {!editable && (
                    <Col md={12}>
                        <ListingLabel>Description:</ListingLabel>
                        <ListingDescription>{description ? description : 'No description added'}</ListingDescription>

                        {seoTitle ? (
                            <>
                                <ListingLabel>SEO Title:</ListingLabel>
                                <ListingDescription>{`/${seoTitle}`}</ListingDescription>
                            </>
                        ) : null}

                        <ListingLabel>Categories:</ListingLabel>
                        <ListingDescription>
                            {primaryCategories && primaryCategories.length > 0
                                ? primaryCategories.map((category) => <span>{category}</span>)
                                : 'No categories added'}
                        </ListingDescription>

                        <ListingLabel>Tags:</ListingLabel>
                        <ListingTagContainer>
                            {tags?.map((t, idx) => (
                                <ListingTags key={`tag_id_${idx}`}>{t}</ListingTags>
                            ))}
                        </ListingTagContainer>

                        <ListingLabel>License:</ListingLabel>
                        <ListingDescription>
                            {license ? <span>{license}</span> : <span className="error">No license applied</span>}
                        </ListingDescription>
                    </Col>
                )}
                {!editable && (
                    <Col md={12}>
                        {listing.error ? (
                            <GDALError>
                                <h2>GDAL ERROR</h2>
                                <h3>{listing.error}</h3>
                            </GDALError>
                        ) : listing.processing ? (
                            <ErrorPanel>
                                File is currently uploading or post-processing.
                                <br />
                                <p>
                                    If it is taking too long, there may have been an issue with the upload: the user
                                    closed browser, internet connection problem or something else.
                                </p>
                            </ErrorPanel>
                        ) : listing.listingType === ListingType.TILE_LAYER && !listing.tileUrl ? (
                            <Preview src={listing.largePreviewUrl ? listing.largePreviewUrl : listing.previewUrl} />
                        ) : listing.listingType === ListingType.IMAGE ? (
                            <ListingImageMap
                                distoredGeometry={listing.distoredGeometry}
                                previewUrl={listing.previewUrl}
                                editable={false}
                            />
                        ) : (
                            <ListingMap listing={listing} height="500px" width="inherit" />
                        )}

                        {listing.error && <ErrorPanel>GDAL ERROR: {listing.error}</ErrorPanel>}
                    </Col>
                )}
                {!editable && (
                    <Col md={12}>
                        <DetailsButton color="link" onClick={toggle} style={{ marginTop: '10px' }}>
                            Technical Details / Download
                        </DetailsButton>
                        <Collapse isOpen={isOpen}>
                            <Row>
                                {listing.listingType === ListingType.IMAGE && (
                                    <ListingDroneDetails>
                                        <LocationMapContainer>
                                            <SingleFeatureMap locationWKT={listing.geometryWKT} zoom={12} />
                                        </LocationMapContainer>
                                        <ImageDetails>
                                            <FormattedDetailsTable label="Image Details">
                                                {metadata.dateTaken && (
                                                    <Line label={'Date Taken'} value={metadata.dateTaken} />
                                                )}
                                                {metadata.make && (
                                                    <Line
                                                        label={'Make/Model'}
                                                        value={`${metadata.make}/${metadata.model}`}
                                                    />
                                                )}
                                                {metadata.altitude && (
                                                    <Line label={'Altitude'} value={`${metadata.altitude} m`} />
                                                )}
                                                {metadata.streetAddress && (
                                                    <Line label={'Address'} value={metadata.streetAddress} />
                                                )}
                                            </FormattedDetailsTable>
                                        </ImageDetails>
                                    </ListingDroneDetails>
                                )}
                                <TechnicalDetails>
                                    <FormattedDetailsTable label="Details">
                                        {listing.listingType && (
                                            <Line
                                                label={'Type'}
                                                value={ViewHelper.replaceTileLayerTextWithMap(listing.listingType)}
                                            />
                                        )}
                                        <Line
                                            label={'File Name'}
                                            value={ViewHelper.extractFilename(listing.filename)}
                                        />
                                        {listing.filehash && <Line label={'File Hash'} value={listing.filehash} />}
                                        {listing.filesize && (
                                            <Line label={'File Size'} value={filesize(listing.filesize)} />
                                        )}
                                        {listing.gdalInfo && (
                                            <Line
                                                label="GDAL Information"
                                                value={
                                                    <DetailsButton color="link" onClick={() => setModal(true)}>
                                                        View
                                                    </DetailsButton>
                                                }
                                            />
                                        )}

                                        {listing.layerWmsServerId && (
                                            <Line
                                                label="WMS Server Link"
                                                value={
                                                    <a
                                                        href={wmsServerLink(listing)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {`https://${Config.DOMAIN}${wmsServerLink(listing)}`}
                                                    </a>
                                                }
                                            />
                                        )}
                                        {listing.layerName && <Line label="WMS Layer Name" value={listing.layerName} />}
                                        {listing.layerTitle && (
                                            <Line label="WMS Layer Title" value={listing.layerTitle} />
                                        )}
                                        {listing.layerAdminNotes && listing.listingType === ListingType.WMS && (
                                            <Line label="WMS Admin Notes" value={listing.layerAdminNotes} />
                                        )}
                                        {listing.layerAdminNotes &&
                                            listing.listingType === ListingType.EXTERNAL_TILE_LAYER && (
                                                <Line label="TMS Admin Notes" value={listing.layerAdminNotes} />
                                            )}

                                        {!listing.processing &&
                                            listing.listingType !== ListingType.WMS &&
                                            listing.listingType !== ListingType.WMTS &&
                                            listing.listingType !== ListingType.COG &&
                                            listing.listingType !== ListingType.EXTERNAL_TILE_LAYER && (
                                                <Line
                                                    label="Download Image"
                                                    value={
                                                        <DetailsButton color="link" onClick={downloadFile}>
                                                            Download
                                                        </DetailsButton>
                                                    }
                                                />
                                            )}
                                    </FormattedDetailsTable>
                                </TechnicalDetails>
                            </Row>
                        </Collapse>
                    </Col>
                )}
                {!editable && listing.review !== Review.PENDING && (
                    <Col md={12}>
                        <DetailsButton
                            color="link"
                            onClick={() => setIsReviewLogsOpen(!isReviewLogsOpen)}
                            style={{ marginTop: '10px' }}
                        >
                            Review Logs
                        </DetailsButton>
                        <Collapse isOpen={isReviewLogsOpen}>
                            <Row>
                                <TechnicalDetails>
                                    <FormattedDetailsTable>
                                        {listing.review && <Line label={'Review'} value={listing.review} />}
                                        {listing.reviewedByEmail && (
                                            <Line label={'Reviewer'} value={listing.reviewedByEmail} />
                                        )}
                                        {listing.reviewedAt && (
                                            <Line
                                                label={'Reviewed At'}
                                                value={ViewHelper.formatDateWithTime(listing.reviewedAt)}
                                            />
                                        )}
                                        {listing.rejectionReasons && (
                                            <Line
                                                label={'Reason'}
                                                value={
                                                    <List>
                                                        {listing.rejectionReasons.map((reason) => (
                                                            <li>
                                                                <strong>{reason.reason}</strong>
                                                                <br />
                                                                {reason.feedback}
                                                            </li>
                                                        ))}
                                                    </List>
                                                }
                                            />
                                        )}
                                    </FormattedDetailsTable>
                                </TechnicalDetails>
                            </Row>
                        </Collapse>
                    </Col>
                )}
            </Row>
            {listing.gdalInfo && (
                <SoarModal title="GDAL information" isOpen={modal} onClosed={() => setModal(false)}>
                    <ConfirmModalText>
                        <pre>{JSON.stringify(gdal, null, 2)}</pre>
                    </ConfirmModalText>
                    <ConfirmModalFooter>
                        <ConfirmButtonContainer>
                            <Button onClick={() => setModal(false)}>Close</Button>
                        </ConfirmButtonContainer>
                    </ConfirmModalFooter>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default ListingDetails;

const ListingName = styled.h3`
    color: #eed926;

    a {
        color: inherit;
        text-decoration: underline;
    }

    span {
        float: right;
    }
`;

const ListingUploader = styled(SubtitleHint)`
    text-align: left;
    margin: 0px;
    font-size: 14px;
    line-height: 21px;
    padding: 10px 0;

    a {
        color: #eed926;
    }
`;

const ListingDescription = styled.p`
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    margin: 10px 0px;

    span {
        border: 1px solid rgb(255 255 255 / 30%);
        padding: 2px;
        border-radius: 4px;
        margin: 2px;
    }

    span.error {
        color: red;
    }
`;

const ListingTagContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
`;

const ListingTags = styled(SubtitleHint)`
    margin: 0 5px 0px 0px;
    line-height: 30px;
    font-weight: normal;
`;

const ListingLabel = styled(Label)`
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: bold;
`;

const ListingDroneDetails = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
`;

const DetailsButton = styled(Button)`
    padding: 0px;
    margin-top: -4px;
`;

const TechnicalDetails = styled.div`
    margin: 10px 0px;
`;

const LocationMapContainer = styled.div`
    .single-feature-map {
        height: 200px;
    }
`;

const MapContainer = styled.div`
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .single-feature-map {
        width: inherit;
        margin-right: 10px;
        height: 500px;
    }
`;

const Preview = styled.img`
    max-width: 100%;
    max-height: 500px;
`;

const GDALError = styled.div`
    width: 98%;
    height: 500px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    h2 {
        margin-top: 30px;
        font-size: 1.5em;
        text-align: center;
        color: #dc3545;
    }

    h3 {
        text-align: center;
        margin-top: 10px;
        color: white;
    }
`;

const EditAlert = styled(Alert)`
    background-color: #212529;
    border-color: #dc3545;
    color: #dc3545;
    font-size: 16px;

    label {
        margin-top: 5px;
        color: white !important;
    }
`;

const ConfirmEditButtonContainer = styled(ConfirmButtonContainer)`
    float: right;
`;

const SubmitButton = styled(Button)`
    width: 90px;
`;

const ImageDetails = styled.div`
    margin-left: 10px;
`;

const CategoriesMultiselect = styled(Multiselect)`
    color: rgba(33, 37, 41, 1);
`;

const RequiredLabel = styled(Label)`
    span {
        color: red;
    }
`;

const InputHelpText = styled(SubtitleHint)`
    text-align: left;
    color: #eed926;
    font-size: 12px;
    font-style: inherit;
`;
